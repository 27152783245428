import React from "react";
import ReactDOM from "react-dom";

import NextApp from "./NextApp";
import Layout from "./layout";
import registerServiceWorker from "./registerServiceWorker";
// Add this import:
import { AppContainer } from "react-hot-loader";
import { stripeKey } from "util/config";
import { StripeProvider } from "react-stripe-elements";
import $ from "jquery";
const cookie = require("js-cookie");

class App extends React.Component {
  state = {
    stripe: null
  };
  componentDidMount() {
    if (window.Stripe) {
      this.setState({ stripe: window.Stripe(stripeKey) });
    } else {
      var script = document.createElement("script");
      script.src = "https://js.stripe.com/v3/";
      script.onload = () => {
        this.setState({ stripe: window.Stripe(stripeKey) }, () => {
          console.log(window.Stripe);
        });
      };
      document.head.appendChild(script);
    }
    // var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
  //   window.Tawk_API = window.Tawk_API || {};
  //   (function(){
  //     var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
  //     s1.async=true;
  //     s1.src='https://embed.tawk.to/6035e593385de40757195e79/1ev98hj5v';
  //     s1.charset='UTF-8';
  //     s1.setAttribute('crossorigin','*');
  //     s0.parentNode.insertBefore(s1,s0);
  //   })();
  //   window.Tawk_API.customStyle = {
  //     visibility: {
  //       //for desktop only
  //       desktop: {
  //         position: 'bl', // bottom-right
  //         xOffset: 15, // 15px away from right
  //         yOffset: 40 // 40px up from bottom
  //       },
  //       // for mobile only
  //       mobile: {
  //         position: 'bl', // bottom-left
  //         xOffset: 5, // 5px away from left
  //         yOffset: 50 // 50px up from bottom
  //       },
  //       // change settings of bubble if necessary
  //       bubble: {
  //         rotate: '0deg',
  //         xOffset: -20,
  //         yOffset: 0
  //       }
  //     }
  // };
  }
  render() {
    return (
      <StripeProvider stripe={this.state.stripe}>
        <Layout />
      </StripeProvider>
    );
  }
}

// Wrap the rendering in a function:
const render = Component => {
  ReactDOM.render(
    // Wrap App inside AppContainer
    <AppContainer>
      <App />
    </AppContainer>,
    document.getElementById("root")
  );
};

// Do this once
registerServiceWorker();

// Render once
render(<App />);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept("./NextApp", () => {
    render(<App />);
  });
}
