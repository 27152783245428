import React from "react";
import { withRouter } from "react-router-dom";
import { message, Button, Rate } from "antd";
// import { Link } from "react-router-dom";

import TopNavbar from "../components/TopNavbar";

import Description from "./components/Description";
import CourseVideos from "./components/Course-Videos";
import Discussions from "./components/Discussions";
import { imagePrefixUrl } from "util/config";

import CartModel from "./../myCart/model";
import CircularProgress from "components/CircularProgress/index";
import Model from "../../trainings/$id/model";

import ModelTraining from "../../traineeMain/model";
import ModelDiscussion from "./components/model";
import ModelReview from "./../courseIntro/model";
import store from "store";
import IntlMessages from "util/IntlMessages";
import { getSubdomain } from '../../../util/Utils';


var convert = require("xml-js");
class courseDhashboard extends React.PureComponent {
  state = {
    discussions: [],
    discussionsText: "",
    ratingCount: 5,
    givenRating: 0,
    trainingId: "",
    trainingInfo: {},
    campaignInfo: {},
    loader: false,
    loading: false,
    modules: [],
    reviews: [],
    tabName: "Description",
    showDescriptionButton: true,
    enrollNowButton: true,
  };
  componentWillMount() {
    window.scrollTo(0, 0);
    const { match, location } = this.props;
    const { trainingInfoProps, campaignInfoProps } = location;
    const { params } = match;
    console.log({ location });
    this.callUserEnrolled(params.trainingId);
    if (trainingInfoProps) {
      if (params.trainingType === "Assigned") {
        this.setState({
          campaignInfo: campaignInfoProps,
          trainingInfo: trainingInfoProps,
        });
        this.callReviewsApi(trainingInfoProps._id);
        this.callDiscussionApi(trainingInfoProps._id);
      } else {
        this.setState({ trainingInfo: trainingInfoProps });
        this.callReviewsApi(trainingInfoProps._id);
        this.callDiscussionApi(trainingInfoProps._id);
      }

      this.fatchCourseVideo(trainingInfoProps._id, trainingInfoProps.trainingType);
    } else {
      this.callTrainingApi(params.trainingType, params.trainingId);
    }
    var randomRating = Math.floor(1 + Math.random() * 5);
    var randomRatingUser = Math.floor(10 + Math.random() * 150);
    var randomEnrollUser = Math.floor(150 + Math.random() * 400);
    if (randomRating < 3) {
      randomRating = 4;
    }
    this.setState({
      randomRating,
      randomRatingUser,
      randomEnrollUser,
    });
  }
  callTrainingApi(trainingType, id) {
    if (trainingType === "Assigned") {
      var self = this;
      self.setState({
        loader: true,
        noDataFound: false,
        campaignInfo: {},
        trainingInfo: {},
      });
      ModelTraining.fetchTraineeTrainingInfo(id).then((response) => {
        if (!response.error) {
          if (response.data.trainingId) {
            self.setState({
              campaignInfo: response.data,
              trainingInfo: response.data.campaignId.trainingId,
              loader: false,
            });
            this.callReviewsApi(response.data.campaignId.trainingId._id);
            this.callDiscussionApi(response.data.campaignId.trainingId._id);
            this.fatchCourseVideo(
              response.data.campaignId.trainingId._id,
              response.data.campaignId.trainingId.trainingType
            );
          } else {
            self.setState({
              noDataFound: true,
              loader: false,
            });
          }
        } else {
          self.setState({
            noDataFound: true,
            loader: false,
          });
        }

        self.setState({ enrollNowButton: true });
      });
    } else {
      var self = this;
      self.setState({
        loader: true,
        noDataFound: false,
        campaignInfo: {},
        trainingInfo: {},
      });

    const organisationId = store.get("user").organisationId;
      ModelTraining.fetchTrainingInfo(id).then((response) => {
        if (!response.error) {
          self.setState({
            trainingInfo: response.data,
            loader: false,
          });
          if ( !organisationId.isSelfAssign && !(this.state.trainingInfo.trainingRequest && this.state.trainingInfo.trainingRequest.approved)) {
            return this.props.history.push("/trainee/course-intro/" + id + "/" + trainingType);
          }

          this.callReviewsApi(response.data._id);
          this.callDiscussionApi(response.data._id);
          this.fatchCourseVideo(response.data._id, response.data.trainingType);
        } else {
          self.setState({
            noDataFound: true,
            loader: false,
          });
        }
        self.setState({ enrollNowButton: true });
      });
    }
  }
  callUserEnrolled(id){
     ModelTraining.fetchUserEnroledNumber(id).then((response) => {
        if (!response.error && response.data) {
          console.log(response.data,"response data")
          this.setState({
            totalUserEnrolled: response.data
          })
        }
      });
  }
  callReviewsApi(trainingId) {
    ModelReview.getReviews(trainingId).then((response) => {
      if (!response.error) {
        this.setState({
          reviews: response.data,
        });
      }
    });
  }
  callDiscussionApi(trainingId) {
    ModelDiscussion.getDiscussion(trainingId).then((response) => {
      if (!response.error) {
        var { discussions } = this.state;
        // discussions = response.data.concat(discussions);
        discussions = response.data;
        this.setState({
          discussions,
        });
      }
    });
  }

  fatchCourseVideo(_id, trainingType) {
    var self = this;
    if (trainingType !== 4 && trainingType !== 3 && false) {
      self.setState({ modules: [], loading: true });
      fetch("/serve/" + _id + "/xmls/en/course.xml")
        .then((res) => res.text())
        .then((data) => {
          console.log("err---", data.error);
          console.log({ data });
          var result = convert.xml2json(data, { compact: true, spaces: 4 });
          result = JSON.parse(result);
          console.log({ result });
          console.log(result.Error);
          if (!result.Error) {
            var modules = result.course ? result.course.Modules.Module : [];
            console.log(modules);
            self.setState({ modules, loading: false });
          } else {
            self.setState({ loading: false });
          }
        });
    }
  }
  addDiscussion = () => {
    if (this.state.discussionsText.trim().length == 0) {
      message.error("Please enter description.");
      return false;
    }
    this.setState({
      showDescriptionButton: false,
    });
    ModelDiscussion.saveDiscussion(
      this.state.discussionsText,
      this.state.trainingInfo._id,
      store.get("user")._id,
      store.get("user").profilePhoto
    ).then((response) => {
      if (!response.error) {
        var { discussions } = this.state;
        discussions = discussions.concat(response.data);
        this.setState({
          discussions,
          discussionsText: "",
          showDescriptionButton: true,
        });
      }
    });
  };
  setDiscussion = (e) => {
    this.setState({
      discussionsText: e.target.value,
    });
  };
  enrollNow = (trainingId, item) => {
    //console.log({ trainingId });

    const organisationId = store.get("user").organisationId;
    const { match } = this.props;
    const { params } = match;
    this.setState({ enrollNowButton: false });
    var self = this;

    // this.state.trainingInfo.trainingRequest &&

    if (
      organisationId.isSelfAssign &&
      !(
        (item.trainingRequest && item.trainingRequest._id) 
      )
    ) {
      Model.assignTraining([store.get("user")], trainingId).then((response) => {
        if (response.error) {
          message.error(response.userMessage);
          self.setState({ enrollNowButton: true });
        } else {
          store.set("notificationCount", parseInt(store.get("notificationCount")) + 1);
          var event = new CustomEvent("notification", {
            detail: {
              notifications: response.data,
              status: 0,
            },
          });
          document.dispatchEvent(event);
          message.success(
            response.userMessage,
            (onclose = function() {
              self.callTrainingApi(params.trainingType, params.trainingId);
              // self.props.history.push(params.trainingType=="Practice"? "/trainee/course-dhashboard/" + item._id + '/'+ params.trainingType: "/trainee/course-intro/" + item._id + '/'+ params.trainingType)
            })
          );
        }
      });
    } else {
      Model.trainingRequest(trainingId).then((response) => {
        if (response.error) {
          message.error(response.userMessage);
          self.setState({ enrollNowButton: true });
        } else {
          message.success(
            response.userMessage,
            (onclose = function() {
              self.callTrainingApi(params.trainingType, params.trainingId);
            })
          );
        }
      });
    }
  };

  onClickAddToCart = (training) => {
    var storeTraining = store.get("myCart");
    if (!storeTraining.some((info) => info.trainingId._id === training._id)) {
      CartModel.actionCart(training._id, "add").then((response) => {
        if (!response.error) {
          let trainerInfo = {};
          trainerInfo["trainingId"] = training;
          storeTraining.push(trainerInfo);
          store.set("myCart", storeTraining);

          var event = new CustomEvent("myCart", {
            detail: { storeTraining },
          });
          document.dispatchEvent(event);
          message.success(response.userMessage);
        } else {
          message.error(response.userMessage);
        }
      });
    } else {
      message.success("Added to Cart");
    }
  };
  render() {
    const { loader, match, training, traineeTraining } = this.props;
    const { params } = match;
    const { randomRating, randomRatingUser, randomEnrollUser } = this.state;
    if (loader) {
      return <CircularProgress></CircularProgress>;
    }

    const tablinks = (tabName) => {
      this.setState({ tabName });
    };
    var ln = [];
    var rating = 0;
    if (this.state.trainingInfo && this.state.trainingInfo.rating) {
      rating = this.state.trainingInfo.rating ? this.state.trainingInfo.rating : 0;
    }

    rating = this.state.reviews.reduce(function(total, current) {
      return (total = total + current.rating);
    }, 0);

    rating = rating / this.state.reviews.length;
    rating = Math.round(rating);
    console.log({ rating });
    for (var i = 0; i < rating; i++) {
      ln.push(i);
    }
    var services = store.get("user").organisationId.services;
    const isCscuServiseAvailable = services.includes("cscu");

    const discussionsProps = {
      discussions: this.state.discussions,
      setDiscussion: this.setDiscussion,
      addDiscussion: this.addDiscussion,
      discussionsText: this.state.discussionsText,
      showDescriptionButton: this.state.showDescriptionButton,
    };

    return (
      <div>
        <TopNavbar key="topNavbarKey" />

        {this.state.loader ? <CircularProgress></CircularProgress> : ""}
        {!this.state.loader && this.state.trainingInfo && params.trainingType === "Assigned" && (
          <div
            className="course-dhashboard topic2"
            style={{
              backgroundImage: "url(" + require("assets/images/backgrounds/course-dhashboard-bg.jpg") + ")",
            }}
          >
            <div data-uk-grid>
            {getSubdomain() !== "bse" && this.state.trainingInfo.trainingType != 4 && getSubdomain() != "hdfclifelearning" && getSubdomain() != "hdfcpensionlearning"  && getSubdomain() != "hdfcreinsurancelearning" &&  (
              <div className="uk-width-1-2@m uk-padding-remove-left uk-visible@m">
                <div className="course-video-demo uk-position-relative">
                  <video
                    loop
                    // muted
                    // playsinline
                    controls
                    data-uk-video="autoplay: inview"
                  >
                    <source
                      src={
                        this.state.trainingInfo && this.state.trainingInfo.previewVideo
                          ? `${imagePrefixUrl}${this.state.trainingInfo.previewVideo}`
                          : "videos/course-intro.mp4"
                      }
                      type="video/mp4"
                    />
                  </video>
                  {/* --> */}
                </div>
              </div>
            )}
            {
            (getSubdomain() == "hdfclifelearning" || getSubdomain() == "hdfcpensionlearning" || getSubdomain() == "hdfcreinsurancelearning") && 
              <div className="uk-width-1-2@m uk-padding-remove-left uk-visible@m">
                <div className="course-video-demo uk-position-relative">
                  <img style={{width:'560px',height:'300px'}} src="https://api.ohphish.com/api/assets/hdfc_banner_image.png"/>
                </div>
              </div>
            }
              <div id="font-size-p" className="uk-width-1-2@m uk-padding">
                <h2 className={this.state.trainingInfo.language == "ar" ?"uk-light uk-text-uppercase uk-text-bold uk-text-white rtl-text":"uk-light uk-text-uppercase uk-text-bold uk-text-white "}>
                  {this.state.trainingInfo.name}
                </h2>
                <p className={this.state.trainingInfo.language == "ar" ?"uk-light uk-text-bold rtl-text":"uk-light uk-text-bold"}>{this.state.trainingInfo.shortDescription}</p>

                {/* <p className="uk-light uk-text-bold uk-text-small"> */}
                  {/* {ln.map(item => {
                      return (
                        <i
                          key={"icon-rate" + item}
                          className="fas fa-star ic-icon-small ic-icon-rate"
                        ></i>
                      );
                    })} */}
                  {/* <Rate disabled value={rating ? rating : 0} /> */}
                  {/* <Rate disabled value={randomRating} />
                  &nbsp;&nbsp; */}
                  {/* {rating ? rating + ".0" : 0} &nbsp;&nbsp; ({this.state.reviews ? this.state.reviews.length : `0`}{" "}
                  ratings){" "} */}
                  {/* {randomRating} &nbsp;&nbsp; ({randomRatingUser} ratings){" "}
                </p> */}
                {/* <!-- students images  -->                          */}
                {getSubdomain() !== "bse" && (
                  <div
                    className="avatar-group uk-margin"
                    data-uk-scrollspy="target: > img; cls:uk-animation-slide-right; delay: 200"
                  >
                    <img src={require("assets/images/avatures/avature-1.png")} alt="" />
                    <img src={require("assets/images/avatures/avature.jpg")} alt="" />
                    <img src={require("assets/images/avatures/avature-2.png")} alt="" />

                        <span className="uk-text-bold uk-light">
                        {/* {this.state.campaignInfo.userEnrolled ? this.state.campaignInfo.userEnrolled : `0`} students
                        enrolled */}
                        {this.state.totalUserEnrolled || 0} <IntlMessages id={this.state.totalUserEnrolled && this.state.totalUserEnrolled == 1 ? "trainee.enrolled" : "trainee.enroll"}/> 

                      </span>
                  </div>
                )}
                <div className="uk-grid-small" data-uk-grid>
                  <div className="uk-width-auto">
                    {(!this.state.campaignInfo.submitted &&
                      (this.state.campaignInfo.isPurchase ||
                        this.state.trainingInfo.isFree ||
                        this.state.trainingInfo.trainingType === 4)) ||
                    (isCscuServiseAvailable && this.state.trainingInfo.trainingType === 3) ? (
                      <a
                        className="uk-button uk-button-white uk-float-left"
                        onClick={(e) => {
                          e.preventDefault();
                          //console.log(store.get("user"));
                          if (
                            !this.state.campaignInfo.userEnrolled &&
                            !this.state.campaignInfo.isPurchase &&
                            !this.state.trainingInfo.isFree
                          ) {
                            message.error("Please purchase this training after continue", 20);
                          } else {
                            this.props.history.push(
                              `${
                                this.state.campaignInfo
                                  ? "/trainee/play/" +
                                    this.state.campaignInfo.campaignId._id +
                                    "/" +
                                    params.trainingType
                                  : ""
                              }`
                            );
                          }
                        }}
                        data-uk-tooltip="title: Start this course now  ; delay: 300 ; pos: top ;animation:	uk-animation-slide-bottom-small"
                      >
                        {this.state.campaignInfo.suspendData ? 
                     <IntlMessages id="trainee.resume"/>  : 
                     <IntlMessages id="trainee.continue"/> }
                      </a>
                    ) : (
                      <a
                        className="uk-button uk-button-white uk-float-left"
                        onClick={(e) => {
                          e.preventDefault();

                          this.props.history.push(
                            `${
                              this.state.campaignInfo
                                ? "/trainee/play/" + this.state.campaignInfo.campaignId._id + "/" + params.trainingType
                                : ""
                            }`
                          );
                        }}
                        data-uk-tooltip="title: Start this course now  ; delay: 300 ; pos: top ;animation:	uk-animation-slide-bottom-small"
                      >
                        {this.state.campaignInfo.suspendData ? <IntlMessages id="trainee.resume"/>  : 
                     <IntlMessages id="trainee.continue"/>}
                      </a>
                      // <a
                      //   className="uk-button uk-button-white uk-float-left"
                      //   onClick={() => {
                      //     this.onClickAddToCart(this.state.trainingInfo);
                      //   }}
                      //   data-uk-tooltip="title: Start this course now  ; delay: 300 ; pos: top ;animation:	uk-animation-slide-bottom-small"
                      // >
                      //   {" "}
                      //   Add to Cart
                      // </a>
                    )}
                  </div>
                  {(this.state.campaignInfo.isPurchase || this.state.campaignInfo.isFree) && (
                    <div className="uk-width-expand">
                      <span className="uk-light uk-text-small uk-text-bold"> My progress </span>
                      {!this.state.campaignInfo.submitted || !this.state.campaignInfo.suspendData ? (
                        <progress
                          id="js-progressbar"
                          className="uk-progress progress-green uk-margin-small-bottom uk-margin-small-top"
                          value="0"
                          max="100"
                          style={{ height: "8px" }}
                        ></progress>
                      ) : this.state.campaignInfo.completionStatus === "completed" ? (
                        <progress
                          id="js-progressbar"
                          className="uk-progress progress-green uk-margin-small-bottom uk-margin-small-top"
                          value="100"
                          max="100"
                          style={{ height: "8px" }}
                        ></progress>
                      ) : (
                        <progress
                          id="js-progressbar"
                          className="uk-progress progress-green uk-margin-small-bottom uk-margin-small-top"
                          value="45"
                          max="100"
                          style={{ height: "8px" }}
                        ></progress>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {!this.state.loader && this.state.trainingInfo && params.trainingType !== "Assigned" && (
          <div
            className="course-dhashboard topic2"
            style={{
              backgroundImage: "url(" + require("assets/images/backgrounds/course-dhashboard-bg.jpg") + ")",
            }}
          >
            <div data-uk-grid>
              <div className="uk-width-1-2@m uk-padding-remove-left uk-visible@m">
                <div className="course-video-demo uk-position-relative">
                  <video
                    loop
                    // muted
                    // playsinline
                    controls
                    data-uk-video="autoplay: inview"
                  >
                    <source
                      src={
                        this.state.trainingInfo && this.state.trainingInfo.previewVideo
                          ? `${imagePrefixUrl}${this.state.trainingInfo.previewVideo}`
                          : "videos/course-intro.mp4"
                      }
                      type="video/mp4"
                    />
                  </video>
                  {/* --> */}
                </div>
              </div>
              <div id="font-size-p" className="uk-width-1-2@m uk-padding">
                <h2 className={this.state.trainingInfo.language == "ar" ?"uk-light uk-text-uppercase uk-text-bold uk-text-white rtl-text":"uk-light uk-text-uppercase uk-text-bold uk-text-white"}>
                  {this.state.trainingInfo.name}
                </h2>
                <p className={this.state.trainingInfo.language == "ar" ?"uk-light uk-text-bold rtl-text":"uk-light uk-text-bold"}>{this.state.trainingInfo.shortDescription}</p>

                {/* <p className="uk-light uk-text-bold uk-text-small"> */}
                  {/* {ln.map(item => {
                      return (
                        <i className="fas fa-star ic-icon-small ic-icon-rate"></i>
                      );
                    })} */}
                  {/* <Rate disabled value={rating ? rating : 0} /> */}
                  {/* <Rate disabled value={randomRating} />
                  &nbsp;&nbsp; */}
                  {/* {rating ? rating + ".0" : 0} &nbsp;&nbsp; ({this.state.reviews ? this.state.reviews.length : `0`}{" "}
                  ratings){" "} */}
                  {/* {randomRating} &nbsp;&nbsp; ({randomRatingUser} ratings){" "}
                </p> */}
                {/* <!-- students images  -->                          */}
                <div
                  className="avatar-group uk-margin"
                  data-uk-scrollspy="target: > img; cls:uk-animation-slide-right; delay: 200"
                >
                  <img src={require("assets/images/avatures/avature-1.png")} alt="" />
                  <img src={require("assets/images/avatures/avature.jpg")} alt="" />
                  <img src={require("assets/images/avatures/avature-2.png")} alt="" />
                  <span className="uk-text-bold uk-light">
                    {/* {this.state.trainingInfo.userEnrolled ? this.state.trainingInfo.userEnrolled : `0`} students
                    enrolled */}
                    {this.state.totalUserEnrolled || 0} <IntlMessages id={this.state.totalUserEnrolled && this.state.totalUserEnrolled == 1 ? "trainee.enrolled" : "trainee.enroll"}/> 

                  </span>
                </div>
                <div className="uk-grid-small" data-uk-grid>
                  {!(this.state.trainingInfo.trainingRequest && this.state.trainingInfo.trainingRequest._id) ||
                  (this.state.trainingInfo.trainingRequest &&
                    (this.state.trainingInfo.trainingRequest.approved ||
                      this.state.trainingInfo.trainingRequest.rejected)) 
                      ||(store.get("user") && store.get("user").organisationId.isSelfAssign)
                      ? (
                    <>
                      {this.state.trainingInfo.enrolled && this.state.trainingInfo.enrolled.length > 0 ? (
                        <div className="uk-width-auto">
                          <a
                            className="uk-button uk-button-white uk-float-left"
                            onClick={(e) => {
                              e.preventDefault();
                              if (
                                !(this.state.trainingInfo.trainingType == 3 && isCscuServiseAvailable) &&
                                !this.state.trainingInfo.isPurchase &&
                                this.state.trainingInfo.trainingType !== 4 &&
                                !(this.state.trainingInfo.enrolled && this.state.trainingInfo.enrolled.length > 0)
                              ) {
                                message.error("Please purchase this training after continue", 20);
                              } else {
                                this.props.history.push(
                                  this.state.trainingInfo.enrolled
                                    ? "/trainee/play/" + this.state.trainingInfo.enrolled[0].campaignId + "/Assigned"
                                    : ""
                                );
                              }
                            }}
                            data-uk-tooltip="title: Start this course now  ; delay: 300 ; pos: top ;animation:	uk-animation-slide-bottom-small"
                          >
                            {this.state.trainingInfo.enrolled && this.state.trainingInfo.enrolled.length > 0
                              ? this.state.trainingInfo.enrolled.suspendData
                                ? <IntlMessages id="trainee.resume"/>  : 
                     <IntlMessages id="trainee.continue"/>
                              : <IntlMessages id="trainee.enrollNow"/>}
                          </a>
                        </div>
                      ) : (
                        <div className="uk-width-auto">
                          {this.state.trainingInfo.isFree ||
                          this.state.trainingInfo.trainingType === 4 ||
                          (this.state.trainingInfo.trainingType === 3 &&
                            isCscuServiseAvailable &&
                            !store.get("user").trial) ||
                          !this.state.trainingInfo.enrolled ||
                          this.state.trainingInfo.isPurchase ||
                          this.state.trainingInfo.trainingType === 4 ? (
                            <Button
                              onClick={() => {
                                if (
                                  !this.state.trainingInfo.isPurchase &&
                                  this.state.trainingInfo.trainingType !== 4 &&
                                  !this.state.trainingInfo.isFree
                                ) {
                                  message.error("Please purchase this training after continue", 5);
                                } else {
                                  this.enrollNow(this.state.trainingInfo._id, this.state.trainingInfo);
                                }
                              }}
                              disabled={this.state.enrollNowButton ? false : true}
                              className="uk-button uk-button-white uk-float-left"
                              // to="/trainee/course-lesson"
                            >
                              <IntlMessages id="trainee.enrollNow"/>
                            </Button>
                          ) : (
                            <>
                            {this.state.trainingInfo.trainingRequest && this.state.trainingInfo.trainingRequest.rejected && (
                            <Button
                              onClick={() => {
                                this.enrollNow(this.state.trainingInfo._id, this.state.trainingInfo);
                              }}
                              disabled={this.state.enrollNowButton ? false : true}
                              className="uk-button uk-button-white uk-float-left"
                              // to="/trainee/course-lesson"
                              data-uk-tooltip="title:  Request is declined by Admin  ; delay: 300 ; pos: top ;animation:	uk-animation-slide-bottom-small"
                     
                            >
                              <IntlMessages id="trainee.enrollAgain"/>
                            </Button>

                            ) 
                            }
                            </>
                            // <Button
                            //   onClick={() => {
                            //     this.onClickAddToCart(training);
                            //   }}
                            //   className="uk-button uk-button-white uk-float-left"
                            //   // to="/trainee/course-lesson"
                            // >
                            //   Add to Cart
                            // </Button>
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={() => {
                          message.info("Enroll now request sent to admin successfully.", 5);
                        }}
                        // disabled
                        className="uk-button uk-button-white uk-float-left"
                      >
                       <IntlMessages id="trainee.enrollNow"/> 
                      </Button>
                    </>
                  )}

                  {/* <div className="uk-width-expand">
                              <span className="uk-light uk-text-small uk-text-bold"> My progress </span>
                              <progress id="js-progressbar" className="uk-progress progress-green uk-margin-small-bottom uk-margin-small-top" value="0" max="100" style={{height: '8px'}}></progress>
                          </div> */}
                </div>
              </div>
            </div>
          </div>
        )}

        {!this.state.loader && this.state.trainingInfo && (
          <div>
            <ul className="uk-child-width-expand course-dhashboard-subnav" data-uk-tab>
              <li className="uk-active">
                <a href="#" className="tablinks" onClick={() => tablinks("Description")}>
                  {" "}
                  <IntlMessages
                id="trainee.description"/>{" "}
                </a>
              </li>
              {this.state.trainingInfo.trainingType !== 4 &&
                this.state.trainingInfo.trainingType !== 3 &&
                this.state.modules.length > 0 && (
                  <li>
                    <a href="#" className="tablinks" onClick={() => tablinks("Course-Videos")}>
                      {" "}
                      <span className="uk-visible@m"> <IntlMessages
                id="trainee.course"/> </span> Videos{" "}
                    </a>
                  </li>
                )}
            {(getSubdomain() != "hdfclifelearning" && getSubdomain() != "hdfcpensionlearning") && getSubdomain() != "hdfcreinsurancelearning" && (
              <li>
                <a href="#" className="tablinks" onClick={() => tablinks("Discussions")}>
                  {" "}
                  <IntlMessages
                id="trainee.discussion"/>{" "}
                </a>
              </li>
            )}
            </ul>
            <div className="uk-container tm-hero">
              <div data-uk-grid>
                {/* <!-- page contant --> */}
                <div className="uk-width-2-3@m uk-first-column">
                  {/* <!-- Description tab  -->                          */}
                  {this.state.tabName === "Description" && (
                    <Description key="description" description={this.state.trainingInfo.description} language={this.state.trainingInfo.language} />
                  )}
                  {/* <!-- Course-Videos tab  --> */}
                  {this.state.tabName === "Course-Videos" &&
                    this.state.trainingInfo.trainingType !== 4 &&
                    this.state.modules.length > 0 && (
                      <CourseVideos
                        key="courseVideosInfo"
                        modules={this.state.modules}
                        loading={this.state.loading}
                        trainingInfo={this.state.trainingInfo}
                      />
                    )}
                  {/* <!-- Discussions tab  --> */}
                  {this.state.tabName === "Discussions" && (
                    <Discussions
                      key="discussionsProps"
                      {...discussionsProps}
                      discussionsInfo={this.state.trainingInfo._id}
                    />
                  )}
                </div>
              </div>
            </div>
            {/* )} */}
          </div>
        )}
      </div>
    );
  }
}

// export default courseDhashboard;

export default withRouter(courseDhashboard);
