import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { imagePrefixUrl } from "util/config";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "../../../../components/CircularProgress/index.js";

import { Row, Col } from "antd";
import Model from "../../model.js";
import { func } from "prop-types";
import store from "store";
import IntlMessages from "util/IntlMessages";

const formateDateTime = (date) => {
  return moment(date).format("DD MMM YYYY,  hh:mm A");
};
var languageData = [
  { label: "All", value: "all" },
  { label: "English(United States)", value: "en-us" },
  // { label: "English(Australia)", value: "en-au" },
  // { label: "Bahasa Malaysia", value: "myb" },
  { label: "Arabic", value: "ar" },
]
// const completionStatusList = ["All", "Completed", "Pending"];
const completionStatusList = ["All", "Passed", "Failed", "Pending"];
class CatagoryInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allTrainings: [],
      pagination: {
        totalRecords: 0,
        page: 0,
      },
      loading: true,
      loader: false,
      completionStatus: "All",
      bookmarkStatus: true,
      scrollThreshold: "200px",
      selectedLng:"all",
      selectedLngName:"All",
      isGiedea:false
    };
  }
  componentWillMount() {
    var browserZoomLevel = Math.round(window.devicePixelRatio * 100);
    console.log({ browserZoomLevel });
    if (browserZoomLevel > 67) {
      this.setState({ scrollThreshold: "200px" });
    } else {
      this.setState({ scrollThreshold: "-100px" });
    }

    const isGiedea = store.get("user").organisationId ? store.get("user").organisationId.isGiedea : false; 
    this.setState({ isGiedea });
    var self = this;
    const { trainingType } = self.props;
    Model.fetchTraineeTrainingInfo().then(function(response) {
      self.setState({
        allTrainings: response.data,
        pagination: {
          totalRecords: response.totalRecords,
          page: 0,
        },
        loading: false,
      });
    });
  }

  loadMore = () => {
    var self = this;
    var { pagination, allTrainings, completionStatus } = this.state;

    this.setState({ loading: true });
    Model.fetchTraineeTrainingInfo(null, pagination.page + 1, completionStatus).then((response) => {
      self.setState({
        allTrainings: allTrainings.concat(response.data),
        pagination: {
          page: pagination.page + 1,
          totalRecords: response.totalRecords,
        },
        loading: false,
      });
    });
  };

  bookmarksAction(action, index, info) {
    var self = this;
    var allTrainings = self.state.allTrainings;

    self.setState({ bookmarkStatus: false });
    Model.actionBookmarks(action, info.trainingId).then(function(response) {
      if (action === "remove") {
        info["bookmark"] = false;
        info["totalBookmarks"] = info["totalBookmarks"] - 1;
        allTrainings[index] = info;
        self.setState({ allTrainings, bookmarkStatus: true });
      } else {
        info["bookmark"] = true;
        info["totalBookmarks"] = info["totalBookmarks"] + 1;
        allTrainings[index] = info;
        self.setState({ allTrainings, bookmarkStatus: true });
      }
    });
  }
  shortByCompletionStatus(completionStatus) {
    var {selectedLng} = this.state;
    if (this.state.completionStatus !== completionStatus) {
      this.setState({ completionStatus, loading: true, allTrainings: [] });
      var self = this;
      Model.fetchTraineeTrainingInfo(null, 0, completionStatus, selectedLng).then(function(response) {
        self.setState({
          allTrainings: response.data,
          pagination: {
            totalRecords: response.totalRecords,
            page: 0,
          },
          loading: false,
        });
      });
    }
  }
  shortByLanguage(selectedLng, selectedLngName) {
    var {completionStatus} = this.state;
    if (this.state.selectedLng !== selectedLng) {
      this.setState({ selectedLng, selectedLngName,loading: true, allTrainings: [] });
      var self = this;
      Model.fetchTraineeTrainingInfo(null, 0, completionStatus, selectedLng).then(function(response) {
        self.setState({
          allTrainings: response.data,
          pagination: {
            totalRecords: response.totalRecords,
            page: 0,
          },
          loading: false,
        });
      });
    }
  }
  displayTime(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? (h > 9 ? h + ":" : "0" + h + ":") : "";
    var mDisplay = m > 0 ? (m > 9 ? m + ":" : "0" + m + ":") : "00:";
    var sDisplay = s > 0 ? (s > 9 ? s : "0" + s) : "00";
    return hDisplay + mDisplay + sDisplay;
  }

  render() {
    var disabled = {
      pointerEvents: "none",
      cursor: "default",
    };
    var allTraining = this.state.allTrainings.filter((t) => t.campaignId.trainingId != null);
    var pagination = this.state.pagination;
    const displayPrice = (price) => {
      return parseFloat(price).toFixed(2);
    };
    return (
      <div>
        <div className="uk-container">
          <div className="uk-clearfix boundary-align">
            <div className="uk-float-left section-heading none-border">{/* <h2>Assigned Training</h2>  */}</div>
            {/* {this.state.isGiedea && false ?
            
            <div className="uk-float-right uk-margin-bottom">
            {" "}
          
              <p className="uk-text-small   uk-margin-remove" style={{paddingLeft: "5px"}}>
                {" "}Language:{" "}
                <button
                className="uk-button uk-button-default uk-background-default uk-button-small"
                type="button"
                style={{ width: "200px", textAlign: "left" }}
              >
                {" "}
                {this.state.selectedLngName}{" "}
              </button>
              <div
                data-uk-dropdown="pos: top-right ;mode : click"
                className="uk-dropdown  uk-dropdown-top-right"
                style={{
                  right: "40px",
                  // left: "-141px",
                  top: "-267px",
                  padding: "8px 20px",
                  borderRadius: "5px",
                  border: "1px solid rgba(130, 130, 130, 0.48)",
                  boxShadow: "2px 5px 15px rgba(0, 0, 0, 0.45)",
                  zIndex: 1,
                  minWidth: "150px"
                }}
              >
                <ul className="uk-nav uk-dropdown-nav">
                  {languageData.map((item, index) => {
                    return (
                      <li
                        key={"langauageList" + index}
                        className={this.state.selectedLng.value === item ? "uk-active" : ""}
                      >
                        <a
                          href="#"
                          onClick={() => {
                            this.shortByLanguage(item.value,item.label);
                          }}
                        >
                          {item.label}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div> 
              </p>
            </div>
            :
            ""} */}
            <div className="uk-float-right uk-margin-bottom">
              <p className="uk-text-small  uk-margin-remove">
                <i className="fas fa-filter "></i><IntlMessages
                id="mainTraining.filter"/>{" "}
                {/* <button
                className={this.state.completionStatus === "All" ? "uk-button uk-button-default uk-background-default uk-button-small" : "uk-button uk-button-default uk-background-default uk-button-small uk-active"}
                type="button"
                style={{ width: "150px", textAlign: "left" }}
                onClick={() => 
                {
                  this.shortByCompletionStatus("All");
                }}
              >
                All
              </button>
              <button
                className={this.state.completionStatus === "Completed" ? "uk-button uk-button-default uk-background-default uk-button-small" : "uk-button uk-button-default uk-background-default uk-button-small uk-active"}
                type="button"
                style={{ width: "150px", textAlign: "left" }}
                onClick={() => 
                {
                  this.shortByCompletionStatus("Completed");
                }}
              >
                Completed
              </button>
              <button
                className={this.state.completionStatus === "Pending" ? "uk-button uk-button-default uk-background-default uk-button-small" : "uk-button uk-button-default uk-background-default uk-button-small uk-active"}
                type="button"
                style={{ width: "150px", textAlign: "left" }}
                onClick={() => 
                {
                  this.shortByCompletionStatus("Pending");
                }}
              >
                Pending
              </button> */}
              <div
                data-uk-dropdown="pos: top-right ;mode : click"
                className="uk-dropdown  uk-dropdown-top-right"
                style={{
                  right: "40px",
                  // left: "-141px",
                  top: "-267px",
                  padding: "8px 20px",
                  borderRadius: "5px",
                  border: "1px solid rgba(130, 130, 130, 0.48)",
                  boxShadow: "2px 5px 15px rgba(0, 0, 0, 0.45)",
                  zIndex: 1,
                  minWidth: "150px"
                }}
              >
                <ul className="uk-nav uk-dropdown-nav">
                  {completionStatusList.map((item, index) => {
                    return (
                      <li
                        key={"StatusList" + index}
                        className={this.state.completionStatus === item ? "uk-active" : ""}
                      >
                        <a
                          href="#"
                          onClick={() => {
                            this.shortByCompletionStatus(item);
                          }}
                        >
                          {item}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div> 
              </p>
            </div>
            {this.state.loader && (
              <div
                style={{
                  position: "fixed",
                  zIndex: "+99",
                  left: "50%",
                  top: "35%",
                }}
              >
                <CircularProgress></CircularProgress>
              </div>
            )}
          </div>

          <div className="uk-clearfix boundary-align">
            {allTraining.length > 0 ? (
              <InfiniteScroll
                dataLength={allTraining.length / 9}
                next={this.loadMore}
                scrollThreshold={this.state.scrollThreshold}
                // hasMore={pagination.page + 1 * 9 < pagination.totalRecords}
                loader={this.state.loading ? <CircularProgress></CircularProgress> : ""}
              >
                <div
                  className="uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-match uk-margin"
                  data-uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200"
                  data-uk-grid
                >
                  {allTraining &&
                    allTraining.map((item, index) => {
                      return (
                        <div key={"allTraining" + index}>
                          <div
                            className="uk-card-default uk-card-hover uk-card-small Course-card uk-inline-clip uk-transition-toggle"
                            tabindex="0"
                          >
                            {/* {item.campaignId.trainingId.price &&
                            item.campaignId.trainingId.price !== 0 ? (
                              <div
                                // onClick={() => viewTraining(index, data._id)}
                                className=" uk-position-top-left uk-padding-small uk-padding-remove-left uk-position-z-index"
                                style={{ width: "100px", cursor: "pointer" }}
                              >
                                <img
                                  src={require("assets/images/training/price_tag_image.png")}
                                />
                                <strong
                                  style={{
                                    position: "absolute",
                                    left: "15px",
                                    bottom: "16px",
                                    fontSize: "18px",
                                    fontWeight: "500"
                                  }}
                                >
                                  {item.campaignId.trainingId.price &&
                                  item.campaignId.trainingId.price !== 0
                                    ? "$" +
                                      displayPrice(
                                        item.campaignId.trainingId.price
                                      )
                                    : " "}
                                </strong>
                              </div>
                            ) : (
                              ""
                            )} */}
                            <div className="uk-transition-slide-right-small uk-position-top-right uk-padding-small uk-position-z-index">
                              <a
                                className="uk-button course-badge"
                                style={this.state.bookmarkStatus ? {} : disabled}
                                onClick={() => this.bookmarksAction(item.bookmark ? "remove" : "add", index, item)}
                              >
                                {" "}
                                {item.bookmark ? (
                                  <i className="fas fa-heart ic-icon-medium " style={{ color: "#1e87f0" }}></i>
                                ) : (
                                  <i className="fas fa-heart ic-icon-medium "></i>
                                )}{" "}
                              </a>
                            </div>
                            <Link
                              // to={{
                              //   pathname:
                              //     "/trainee/course-intro/" +
                              //     item.campaignId._id +
                              //     "/Assigned"
                              // }}
                              to={{
                                pathname: "/trainee/course-intro/" + item.campaignId._id + "/Assigned",
                                // pathname:"/trainee/play/" +
                                // item.campaignId._id +
                                //     "/Assigned",
                                trainingInfoProps: item.campaignId.trainingId,
                                campaignInfoProps: item,
                              }}
                              className="uk-link-reset"
                            >
                              <img
                                src={
                                  item.campaignId.trainingId.coverImage
                                    ? `${imagePrefixUrl}${item.campaignId.trainingId.coverImage}`
                                    : require("assets/images/courses/cover_image.jpg")
                                }
                                className="course-img"
                              />

                              <p className="trainingCounter">{index + 1}</p>

                              <div className="uk-clearfix" style={{ position: "absolute", right: "10px" }}>
                                <div
                                  className="uk-float-right"
                                  style={{
                                    position: "relative",
                                    bottom: "40px",
                                  }}
                                >
                                  {item.campaignId.trainingId.trainingType !== 4 && item.campaignId.trainingId.duration  ? (
                                    <span
                                      className="Course-tags "
                                      style={{
                                        borderRadius: "27px",
                                        background: "#fdfdfd",
                                        padding: "5px 18px",
                                        opacity: "0.8",
                                        fontWeight: "600",
                                        color: "rgb(255, 255, 255)",
                                        border: "1px solid rgb(0, 0, 0)",
                                        background: "rgb(0, 0, 0)",
                                        boxShadow: "0 2px 15px rgba(0, 0, 0, 0.14)",
                                      }}
                                    >
                                      {item.campaignId.trainingId.duration
                                        ? this.displayTime(item.campaignId.trainingId.duration)
                                        : "00:00"}
                                    </span>
                                  ):""}
                                </div>
                              </div>
                              {/* 
                              <div
                                className="uk-clearfix"
                                style={{ margin: "0 10px" }}
                              >
                                {item.campaignId.trainingId.hasAssessment && (
                                  <div
                                    className="uk-float-left"
                                    style={{
                                      position: "absolute",
                                      top: "157px",
                                      backgroundColor: "#ffffff00",
                                      zIndex: "+999"
                                    }}
                                  >
                                    <img
                                      className="Course-tags uk-margin-small-right"
                                      src={require("assets/images/quiz_icon.png")}
                                      style={{
                                        borderRadius: "27px"
                                      }}
                                    />
                                  </div>
                                )}
                                <div
                                  className="uk-float-right"
                                  style={{
                                    position: "relative",
                                    top: "-10px"
                                  }}
                                >
                                  {item.campaignId.trainingId.trainingType !==
                                    4 && (
                                    <span
                                      className="Course-tags "
                                      style={{
                                        borderRadius: "20px",
                                        backgroundColor: "#297eb8",
                                        padding: "2px 18px",
                                        color: "#ffffff",
                                        border: "3px solid #ffffff"
                                      }}
                                    >
                                      {item.campaignId.trainingId.duration &&
                                        item.campaignId.trainingId
                                          .trainingType !== 4 &&
                                        this.displayTime(
                                          item.campaignId.trainingId.duration
                                        )}
                                    </span>
                                  )}
                                </div>
                              </div> */}

                              <div className="uk-card-body">
                                <h4 className={item.campaignId.trainingId.language == "ar"?"rtl-text-right":""}>{item.campaignId.trainingId.name ? item.campaignId.trainingId.name : " "}</h4>
                                <div className="uk-clearfix" style={{ marginTop: "5px" }}>
                                  <div className="uk-float-left">
                                    <h4 style={{ color: "#8e8a84" }}>
                                      {item.campaignId.trainingId.authorName
                                        ? item.campaignId.trainingId.authorName
                                        : ""}
                                    </h4>
                                  </div>
                                </div>
                                <p style={{ marginTop: "0px" }} className={item.campaignId.trainingId.language == "ar"?"rtl-text-right":""}>
                                  {item.campaignId.trainingId.shortDescription
                                    ? item.campaignId.trainingId.shortDescription
                                    : ""}
                                </p>

                                <hr className="uk-margin-remove" />
                                <div className="uk-clearfix " style={{ padding: "10px", cursor: "default" }}>
                                  <div className="uk-float-left uk-margin-right">
                                    <span
                                      className="Course-tags"
                                      style={{
                                        borderRadius: "4px",
                                        background: "rgb(237, 239, 240)",
                                        padding: "4px 10px",
                                      }}
                                    >
                                      {item.campaignId.selfAssigned ? "Self Assigned" : "Assigned by Admin"}
                                    </span>
                                  </div>

                                  <div className="uk-float-right">
                                    <span
                                      className={item.successStatus && item.successStatus == "failed" ? "Course-tags failedTab" : item.completionStatus == "completed" ? "Course-tags completedTab" : "Course-tags pendingTab"}
                                      // style={{
                                      //   borderRadius: "4px",
                                      //   background: "rgb(237, 239, 240)",
                                      //   padding: "4px 10px",
                                      // }}
                                    >
                                      {item.successStatus && item.successStatus == "failed" ? "In Complete" : item.completionStatus == "completed" ? "Completed" : "Pending"}
                                    </span>
                                  </div>
                                  {item.campaignId.trainingId.hasAssessment && (
                                    <div className="uk-float-left uk-margin-right">
                                      <span
                                        className="Course-tags"
                                        style={{
                                          borderRadius: "4px",
                                          background: "rgb(237, 239, 240)",
                                          padding: "4px 10px",
                                        }}
                                      >
                                        <img
                                          src={require("assets/images/training/quiz_icon.png")}
                                          style={{
                                            width: "20px",
                                            padding: "0 5px 5px 0",
                                          }}
                                        />
                                        Quiz
                                      </span>
                                    </div>
                                  )}
                                  {/* <div className="uk-float-left uk-margin-right">
                                    <span
                                      className="Course-tags uk-margin-small-right"
                                      style={{
                                        borderRadius: "4px",
                                        background: "rgb(237, 239, 240)",
                                        padding: "4px 10px"
                                      }}
                                    >
                                      <img
                                        src={require("assets/images/training/bookmark_icon.png")}
                                        style={{
                                          width: "20px",
                                          padding: "0 8px 5px 0"
                                        }}
                                      />
                                      {item.totalBookmarks
                                        ? item.totalBookmarks
                                        : 0}
                                    </span>
                                  </div>
                                 */}
                                </div>

                                <hr className="uk-margin-remove-top" />
                                {/* <div className="uk-clearfix " style={{ padding: "10px", cursor: "default" }}>
                                  Status: {item.completionStatus == "completed" ? "Completed" : "Pending"}
                                </div> */}
                                <div
                                  className="uk-clearfix uk-margin-remove"
                                  style={{
                                    cursor: "default",
                                  }}
                                >
                                  <ul
                                    style={{
                                      listStyleType: "none",
                                      margin: 0,
                                      padding: 0,
                                    }}
                                  >
                                    {item.campaignId.trainingId.trainingSubType &&
                                      item.campaignId.trainingId.trainingSubType != "" && (
                                        <li
                                          style={{
                                            padding: "8px 0",
                                            margin: "4px 0",
                                            float: "left",
                                          }}
                                        >
                                          <a
                                            className="Course-tags  "
                                            style={{
                                              borderRadius: "27px",
                                              background: "#fdfdfd",
                                              padding: "8px 18px",
                                              color: "#464748",
                                              border: "1px solid rgb(232, 229, 229)",
                                            }}
                                          >
                                            {" "}
                                            {item.campaignId.trainingId.trainingSubType}{" "}
                                          </a>
                                        </li>
                                      )}

                                    {item.campaignId.trainingId.trainingType && false && (
                                      <li
                                        style={{
                                          padding: "8px 0",
                                          margin: "4px 0",
                                          float: "right",
                                          // border: '1px solid rgb(232, 229, 229)'
                                        }}
                                      >
                                        <span
                                          className="Course-tags  uk-text-white"
                                          style={{
                                            borderRadius: "27px",
                                            padding: "8px 18px",
                                            background:
                                              item.campaignId.trainingId.trainingType == "1" ||
                                              item.campaignId.trainingId.trainingType == "3"
                                                ? "rgba(224, 153, 66, 0.64)"
                                                : "rgba(78, 72, 64, 0.64)",
                                            color: "#ffffff !important",
                                          }}
                                        >
                                          {" "}
                                          {item.campaignId.trainingId.trainingType == "1" ||
                                          item.campaignId.trainingId.trainingType == "3"
                                            ? "Premium"
                                            : "Basic"}
                                        </span>
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </Link>
                            {/* <div className="uk-card-footer">
                              {item.campaignId.selfAssigned
                                ? "Self Assigned"
                                : "Assigned by Admin"}
                            </div> */}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </InfiniteScroll>
            ) : (
              <div>
                {this.state.loading ? (
                  <CircularProgress></CircularProgress>
                ) : (
                  <div
                    style={{ minHeight: "90vh" }}
                    className="uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-match uk-margin"
                    data-uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div ; delay: 200"
                    data-uk-grid
                  >
                    <div className="uk-child-width-1-4@m"></div>
                    <div className="uk-child-width-2-4@m uk-text-center uk-margin">
                      <div className="uk-text-center uk-margin">
                        {/* <div style={{ padding: "0 20%" }}>
                          <img src={require("assets/images/image.png")} />
                        </div> */}
                        <h3 className="uk-text-center " style={{ margin: "20px 0 0" }}>
                          {/* Congratulations! You have completed all of the assigned training. */}
                          No data found.
                        </h3> 
                        {/* <p className="uk-text-center uk-margin-remove">Please contact your admin for further assistance.</p> */}
                      </div>
                    </div>
                    <div className="uk-child-width-1-4@m"></div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default CatagoryInfo;
