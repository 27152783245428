import React from 'react';  
import IntlMessages from "util/IntlMessages";
import { getSubdomain } from "../../../../util/Utils";


function CatagoryNavbar( info) {
    var  { categoryInfo, index, totalTraining, totalUserCount, totalDiscussion } = info;  
    console.log(totalTraining,"totalTraining")
    let image = require('assets/images/courses/hdfc_banner.png');
    // categoryInfo = {
    //     category : "DEmo cat" ,
    //     totalNumberOfCourse:888,
    //     totalNumberOfStudents:999,
    //     categoryCoverImageUrl:require('assets/images/courses/baner_assigned_training.jpg'),
    //     categoryDescription:"jhfkhgfkjgj"

    // }
    return (  
        <div class= { totalTraining && getSubdomain() === "grab" && index == 1 && true ? 'topicGrab hero-bg' : 'topic'+ index +' hero-bg'}> 
            <div data-uk-grid> 
                <div className="uk-width-1-2@m"> 
                    <h1 className="uk-animation-fade"> <IntlMessages
                id={(getSubdomain() == "hdfclifelearning" || getSubdomain() == "hdfcpensionlearning" || getSubdomain() == "hdfcreinsurancelearning") ? "mainTraining.HDFCheading" : "mainTraining.heading"}/> </h1> 
                    <p>  {categoryInfo.categoryDescription}  </p> 
                    <div className="uk-visible@m uk-animation-slide-bottom-small" data-uk-grid> 
                    
                        { totalTraining && getSubdomain() !== "bse" && false &&
                            <div className="uk-width-1-3@m"> 
                                <img src={require("assets/images/icons/Video.png")} className="img-small"/> 
                                <span className="uk-text-middle uk-text-white"> {totalTraining} <IntlMessages
                id="mainTraining.courses"/> </span> 
                            </div>  
                        }                  
                        { totalUserCount && getSubdomain() !== "bse" && false &&
                            <div className="uk-width-1-3@m"> 
                                <img src={require("assets/images/icons/students.png")} className="img-small"/> 
                                <span className="uk-text-middle uk-text-white"> {totalUserCount} <IntlMessages
                id="mainTraining.students"/> </span> 
                            </div>  
                        }                  
                        { totalDiscussion && getSubdomain() !== "bse" && false &&
                            <div className="uk-width-1-3@m"> 
                                <img src={require("assets/images/icons/Discussion.png")} className="img-small"/> 
                                <span className="uk-text-middle uk-text-white"> {totalDiscussion} <IntlMessages
                id="mainTraining.discussion"/> </span> 
                            </div>  
                        }                                         
                    </div>                                 
                </div>                             
                <div className="uk-width-1-2@m uk-visible@m"> 
                    <img src={(getSubdomain() == "hdfclifelearning" || getSubdomain() == "hdfcpensionlearning" || getSubdomain() == "hdfcreinsurancelearning") ? image : categoryInfo.categoryCoverImageUrl} alt="" className="uk-align-right img-xxlarge"/> 
                </div>                             
            </div>                         
        </div>     
    );
}

export default CatagoryNavbar;